import React from "react";
import { Switch, Route } from "react-router-dom";
import { Layout } from "../_layout/layout";
import BasePage from "./BasePage";
import ErrorsPage from "./error/ErrorsPage";

export function Routes() {
  return (
    <Switch>
      <Route path="/error" component={ErrorsPage} />
      <Layout>
        <BasePage />
      </Layout>
    </Switch>
  );
}
