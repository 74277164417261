import React from "react";
import {Link} from "react-router-dom";

export function ErrorPage1() {
  return (
      <div className="nk-block nk-block-middle wide-xs mx-auto">
          <div className="nk-block-content nk-error-ld text-center">
              <h1 className="nk-error-head">404</h1>
              <h3 className="nk-error-title">Oops! página não encontra</h3>
              <Link to="/page/gd/gds"  className="btn btn-lg btn-primary mt-2"><strong>Voltar para home da plataforma</strong></Link>
          </div>
      </div>
  );
}
