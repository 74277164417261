import React, { Fragment, useEffect, useState } from "react";

import { FiChevronDown, FiMenu, FiX } from "react-icons/fi";
import { Menu, Transition } from "@headlessui/react";
import { useMediaQuery } from "react-responsive";
import { NavLink } from "react-router-dom";

export default function Nav() {
  const [header, setHeader] = useState(false);
  const [navMobile, setNavMobile] = useState(false);
  const [subMenu, setSubMenu] = useState();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 80 ? setHeader(true) : setHeader(false);
    });
  });

  const subMenuItem = [
    {
      nome: "Gestão de Faturas de Energia",
      link: "/solucoes/gestao-de-faturas"
    },
    {
      nome: "Mercado Livre",
      link: "/solucoes/mercado-livre-de-energia"
    },
    {
      nome: "Geração Distribuída",
      link: "/solucoes/erp-geracao-distribuida"
    },
    {
      nome: "Plataforma Comercial",
      link: "/solucoes/plataforma-comercial"
    }
  ];

  return (
    <>
      <nav
        className={`${
          header
            ? "bg-white px-5 py-5 shadow-md fixed left-0 right-0 z-50"
            : "pt-8 "
        }   w-full top-0 transition-all duration-500 px-6 lg:px-14 2xl:px-48  `}
      >
        <div className={` flex lg:block justify-between`}>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <NavLink to="/" className="mr-14">
                {header ? (
                  <img
                    src="/images/logo/logo_acessoenergia.svg"
                    alt="Logo Acesso Energia"
                    aria-label="Vá para tela inicial"
                  />
                ) : (
                  <img
                    src="/images/logo/logo_acessoenergia_branco_verde.svg"
                    alt="Logo Acesso Energia"
                    aria-label="Vá para tela inicial"
                  />
                )}
              </NavLink>
              <ul className="hidden lg:flex items-center space-x-14 z-50">
                <li>
                  <NavLink
                    to="/sobre"
                    activeStyle={{
                      color: "#01C96A",
                      fontWeight: 700
                    }}
                    className={`${
                      header ? "text-gray-900" : "text-white"
                    } font-nunito font-semibold text-lg  flex items-center`}
                  >
                    Sobre
                  </NavLink>
                </li>
                <li>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button
                        className={`${
                          header ? "text-gray-900" : "text-white"
                        } font-nunito font-semibold text-lg  flex items-center`}
                      >
                        Soluções
                        <FiChevronDown
                          className="-mr-1 ml-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-[300px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {subMenuItem.map(item => (
                            <li>
                              <Menu.Item>
                                {({ active }) => (
                                  <NavLink
                                    to={item.link}
                                    activeStyle={{
                                      color: "#01C96A",
                                      fontWeight: 500
                                    }}
                                    className="font-roboto font-regular text-sm text-gray-600 flex items-center px-3 py-2 rounded hover:bg-gray-100 "
                                  >
                                    {item.nome}
                                  </NavLink>
                                )}
                              </Menu.Item>
                            </li>
                          ))}

                          <Menu.Item>
                            {({ active }) => (
                              <a
                                // href="/solucoes/portal-do-cliente"
                                href="#contato"
                                activeStyle={{
                                  color: "#01C96A",
                                  fontWeight: 500
                                }}
                                className="font-roboto font-regular text-sm text-gray-600 flex items-center px-3 py-2 rounded hover:bg-gray-100 "
                              >
                                Portal do Cliente
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                // href="/solucoes/telemetria"
                                href="#contato"
                                activeStyle={{
                                  color: "#01C96A",
                                  fontWeight: 500
                                }}
                                className="font-roboto font-regular text-sm text-gray-600 flex items-center px-3 py-2 rounded hover:bg-gray-100 "
                              >
                                Consultoria
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </li>
              </ul>
            </div>

            <div className="hidden lg:flex">
              <div className="space-x-2.5">
                <a
                  href="https://app2.acessoenergia.com.br/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    className={`${
                      header
                        ? "border-gray-300 hover:text-white text-gray-900 focus:ring-gray-400"
                        : "border-white hover:bg-grayDark text-white"
                    }  relative inline-flex group items-center justify-center py-2.5 px-6 bg-transparent rounded-3xl text-sm font-bai font-bold outline-none focus:ring-4  border transition-all duration-200 ease-out hover:bg-grayDark `}
                  >
                    <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-md group-hover:w-full group-hover:h-10 opacity-10"></span>
                    Área do Cliente
                  </button>
                </a>
                <button
                  onClick={() => {
                    window.scrollBy({
                      top: isTabletOrMobile ? 5000 : 3400,
                      left: 0,
                      behavior: "smooth"
                    });
                  }}
                  className={`${
                    header
                      ? "text-white focus:ring-gray-400 hover:bg-zinc-800"
                      : "border-acesso hover:bg-grayDark text-white"
                  }  relative inline-flex group items-center justify-center py-2.5 px-6 bg-grayDark rounded-3xl text-sm font-bai font-bold outline-none focus:ring-4  transform active:scale-x-75 transition-transform border `}
                >
                  <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-md group-hover:w-full group-hover:h-10 opacity-10"></span>
                  Entre em contato
                </button>
              </div>
            </div>
          </div>
          <div
            onClick={() => setNavMobile(!navMobile)}
            className="lg:hidden cursor-pointer "
          >
            {navMobile ? (
              <FiX
                className={`${
                  header ? "text-3xl text-gray-600 " : "text-3xl text-white"
                } `}
              />
            ) : (
              <FiMenu
                className={`${
                  header ? "text-3xl text-gray-600 " : "text-3xl text-white"
                } `}
              />
            )}
          </div>
        </div>
        {navMobile ? (
          <div className="bg-white border border-gray-200 mt-5 p-2 rounded lg:invisible">
            <ul className="  flex flex-col gap-1">
              <li>
                <NavLink
                  to="/sobre"
                  activeStyle={{
                    color: "#01C96A",
                    fontWeight: 700
                  }}
                  className="font-nunito font-semibold text-lg text-gray-800 flex items-center justify-between px-3 py-2 rounded cursor-pointer hover:bg-gray-100"
                >
                  Sobre
                </NavLink>
              </li>
              <li>
                <div>
                  <div
                    className="font-nunito font-semibold text-lg text-gray-800 flex items-center justify-between px-3 py-2 rounded cursor-pointer hover:bg-gray-100"
                    onClick={() => setSubMenu(!subMenu)}
                  >
                    Soluções
                    <FiChevronDown
                      className="-mr-1 ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </div>
                  {subMenu ? (
                    <div className="mt-1 rounded p-2 bg-gray-50 flex flex-col gap-2">
                      {subMenuItem.map(item => (
                        <li>
                          <NavLink
                            to={item.link}
                            activeStyle={{
                              color: "#01C96A",
                              fontWeight: 700
                            }}
                            className="font-roboto font-medium text-sm text-gray-600 flex items-center px-3 py-2 rounded hover:bg-gray-100"
                          >
                            {item.nome}
                          </NavLink>
                        </li>
                      ))}

                      <a
                        href="#contato"
                        className="font-roboto font-medium text-sm text-gray-600 flex items-center px-3 py-2 rounded hover:bg-gray-100"
                      >
                        Portal do Cliente
                      </a>
                      <a
                        href="#contato"
                        className="font-roboto font-medium text-sm text-gray-600 flex items-center px-3 py-2 rounded hover:bg-gray-100"
                      >
                        Consultoria
                      </a>
                    </div>
                  ) : null}
                </div>
              </li>
            </ul>
            <div className="grid grid-cols-2 gap-2 mt-3 w-full ">
              <a
                href="https://app2.acessoenergia.com.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="relative inline-flex group items-center justify-center py-2.5 px-6 bg-transparent rounded text-gray-900 text-sm font-nunito font-bold outline-none focus:ring-4 focus:ring-gray-400 border border-gray-300 transition-all duration-200 ease-out hover:bg-grayDark hover:text-white w-full">
                  <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-md group-hover:w-full group-hover:h-14 opacity-10"></span>
                  Área do Cliente
                </button>
              </a>
              {/* <a href="#contato"> */}
              <button
                onClick={() => {
                  window.scrollBy({
                    top: isTabletOrMobile ? 5000 : 3400,
                    left: 0,
                    behavior: "smooth"
                  });
                }}
                className="relative inline-flex group items-center justify-center py-2.5 px-6 bg-grayDark rounded text-white text-sm font-nunito font-bold outline-none focus:ring-4 focus:ring-gray-400 transform active:scale-x-75 transition-transform hover:bg-zinc-800 w-full h-full"
              >
                <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-full group-hover:h-24 opacity-10"></span>
                Entre em contato
              </button>
              {/* </a> */}
            </div>
          </div>
        ) : null}
      </nav>
    </>
  );
}
