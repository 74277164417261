import React from "react";
// LayoutContext

export function Layout({ children }) {

  return (<>
                  {children}
    </>);


}
